;(function () {
  
  'use strict';

  // iPad and iPod detection	
  var isiPad = function(){
    return (navigator.platform.indexOf("iPad") != -1);
  };

  var isiPhone = function(){
      return (
      (navigator.platform.indexOf("iPhone") != -1) || 
      (navigator.platform.indexOf("iPod") != -1)
      );
  };



  // Carousel Feature Slide
  var testimonialCarousel = function(){
    
    var owl = $('.owl-carousel-fullwidth');
    owl.owlCarousel({
      animateOut: 'fadeOut',
      items: 1,
      loop: true,
      margin: 0,
      nav: false,
      dots: true,
      smartSpeed: 800,
      autoHeight: false
    });
  };

  var sliderMain = function() {
    
      $('#qbootstrap-slider-hero .flexslider').flexslider({
      animation: "fade",
      slideshowSpeed: 5000,
      directionNav: true,
      start: function(){
        setTimeout(function(){
          $('.slider-text').removeClass('animated fadeInUp');
          $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
        }, 500);
      },
      before: function(){
        setTimeout(function(){
          $('.slider-text').removeClass('animated fadeInUp');
          $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
        }, 500);
      }

      });

  };



  // animate-box
  var contentWayPoint = function() {

    $('.animate-box').waypoint( function( direction ) {

      if( direction === 'down' && !$(this).hasClass('animated') ) {
      
        $(this.element).addClass('fadeInUp animated');
      
      }

    } , { offset: '75%' } );

  };


  // Burger Menu
  var burgerMenu = function() {
    $('body').on('click', '.js-qbootstrap-nav-toggle', function(event){

      if ( $('#navbar').is(':visible') ) {
        $(this).removeClass('active');	
      } else {
        $(this).addClass('active');	
      }

      event.preventDefault();
      
    });

  };


  // Parallax
  var parallax = function() {
    if ( !isiPad() || !isiPhone() ) {
      $(window).stellar();
    }
  };



  // Page Nav
  var clickMenu = function() {
    $('a:not([class="external"])').click(function(event){
      var section = $(this).data('nav-section'),
        navbar = $('#navbar');
        $('html, body').animate({
            scrollTop: $('[data-section="' + section + '"]').offset().top
        }, 500);

        if ( navbar.is(':visible')) {
          navbar.removeClass('in');
          navbar.attr('aria-expanded', 'false');
          $('.js-qbootstrap-nav-toggle').removeClass('active');
        }

        event.preventDefault();
        return false;
    });
  };

  var navigateToAnchor = function() {
    var section = window.location.hash.slice(1)
    if (section != '') {
      $('html, body').animate({
        scrollTop: $('[data-section="' + section + '"]').offset().top
      }, 500);
      window.location.hash = '';
    }
  };

  // Reflect scrolling in navigation
  var navActive = function(section) {

    var $el = $('#navbar > ul');
    $el.find('li').removeClass('active');
    $el.each(function(){
      $(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
    });

  };
  var navigationSection = function() {

    var $section = $('div[data-section]');
    
    $section.waypoint(function(direction) {
        if (direction === 'down') {
          navActive($(this.element).data('section'));
        
        }
    }, {
        offset: '150px'
    });

    $section.waypoint(function(direction) {
        if (direction === 'up') {
          navActive($(this.element).data('section'));
        }
    }, {
        offset: function() { return -$(this.element).height() + 155; }
    });

  };


  // Window Scroll
  var windowScroll = function() {
    var lastScrollTop = 0;

    $(window).scroll(function(event){

         var header = $('#qbootstrap-header'),
        scrlTop = $(this).scrollTop();

      if ( scrlTop > 500 && scrlTop <= 2000 ) {
        header.addClass('navbar-fixed-top qbootstrap-animated slideInDown');
      } else if ( scrlTop <= 500) {
        if ( header.hasClass('navbar-fixed-top') ) {
          header.addClass('navbar-fixed-top qbootstrap-animated slideOutUp');
          setTimeout(function(){
            header.removeClass('navbar-fixed-top qbootstrap-animated slideInDown slideOutUp');
          }, 100 );
        }
      } 
      
    });
  };



  // Animations
  var contentWayPoint = function() {
    var i = 0;
    $('.animate-box').waypoint( function( direction ) {

      if( direction === 'down' && !$(this.element).hasClass('animated') ) {
        
        i++;

        $(this.element).addClass('item-animate');
        setTimeout(function(){

          $('body .animate-box.item-animate').each(function(k){
            var el = $(this);
            setTimeout( function () {
              var effect = el.data('animate-effect');
              if ( effect === 'fadeIn') {
                el.addClass('fadeIn animated');
              } else if ( effect === 'fadeInLeft') {
                el.addClass('fadeInLeft animated');
              } else if ( effect === 'fadeInRight') {
                el.addClass('fadeInRight animated');
              } else {
                el.addClass('fadeInUp animated');
              }

              el.removeClass('item-animate');
            },  k * 50, 'easeInOutExpo' );
          });
          
        }, 50);
        
      }

    } , { offset: '85%' } );
  };


  var inlineSVG = function() {
    $('img.svg').each(function(){
      var $img = $(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');

      $.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find('svg');

          // Add replaced image's ID to the new SVG
          if(typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
          }
          // Add replaced image's classes to the new SVG
          if(typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass+' replaced-svg');
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr('xmlns:a');

          // Replace image with new SVG
          $img.replaceWith($svg);

      }, 'xml');

    });
  };
  
  var localizeNumber = function(number, localizationData) {
    if (number >= 5) {
      return localizationData.many;
    } else if (number >= 2) {
      return localizationData.few;
    } else if (number == 1) {
      return localizationData.one;
    } else if (number == 0) {
      return localizationData.many
    }
  };

  // Set the date we're counting down to
    var countDownDate = new Date("Jul 09, 2022 14:00:00").getTime();
    
    // var countDownDate =  new Date("May 13, 2022, 14:00:00").getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {
      // Get todays date and time
      var now = new Date().getTime();

      // Find the distance between now an the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      var localizedDays = localizeNumber(days, {
        many: "dní",
        few: "dni",
        one: "deň"
      });

      var localizedHours = localizeNumber(hours, {
        many: "hodín",
        few: "hodiny",
        one: "hodina"
      });

      var localizedMinutes = localizeNumber(minutes, {
        many: "minút",
        few: "minúty",
        one: "minúta"
      });

      var localizedSeconds = localizeNumber(seconds, {
        many: "sekúnd",
        few: "sekundy",
        one: "sekunda"
      });

      // Display the result in corresponding elements.
      document.getElementById("days").innerHTML = days +" <small>" + localizedDays + "</small>";
      document.getElementById("hours").innerHTML = hours + " <small>" + localizedHours + "</small>";
      document.getElementById("minutes").innerHTML = minutes + " <small>" + localizedMinutes + "</small>";
      document.getElementById("seconds").innerHTML = seconds + " <small>" + localizedSeconds + "</small>";

      if (distance < 0) {
        // If the count down is finished, write some text 
        clearInterval(x);
        if (now < countDownDate + 14 * 60 * 60 * 1000) {
          document.getElementById("countdown_container").innerHTML = "Svadba prebieha! Schovaj telefón a bav sa!!!"; 
        } else {
          document.getElementById("countdown_container").innerHTML = "Už sme svoji!";
        }
      }
    }, 1000);	
  
    
  var bgVideo = function() {
    $('.player').mb_YTPlayer();
  };
        
  var hasInviteCookie = function() {
    return document.cookie.indexOf("invited=true") !== -1;
  }

  var hideInviteOnly = function() {
    if (hasInviteCookie()) {
      for(var el of document.querySelectorAll(".invite-only")) {
        el.hidden = false;
      }
      for(var el of document.querySelectorAll(".no-invite")) {
        el.remove();
      }
    } else {
      for(var el of document.querySelectorAll(".invite-only")) {
        el.remove();
      }
      for(var el of document.querySelectorAll(".no-invite")) {
        el.hidden = false;
      }
    }
  }

  // Document on load.
  $(function(){
    burgerMenu();
    testimonialCarousel();
    sliderMain();
    clickMenu();
    parallax();
    // windowScroll();
    navigationSection();
    contentWayPoint();
    inlineSVG();
    bgVideo();
    navigateToAnchor();
    hideInviteOnly();
  });


}());